import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials"

export default function About({ data }) {
  let props = data.allWpPage.edges[0].node.AboutUsPage
  let main_text = props.mainText
  let bs_text = props.bottomSection.text
  let bs_title = props.bottomSection.title
  let bs_img = props.bottomSection.image.sourceUrl
  return (
    <Layout>
      <SEO title="About" />
      <section className="about-us">
        <div className="container">
          <h1>About us</h1>
          <div dangerouslySetInnerHTML={{ __html: main_text }} />
        </div>
      </section>
      <section className="testimonials">
        <div className="container container--big">
          <h1>Client Testimonials</h1>
          <Testimonials />
        </div>
      </section>
      <section className="meetTeam">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h1>{bs_title}</h1>
              <div dangerouslySetInnerHTML={{ __html: bs_text }} />
            </div>
            <div className="col-6">
              <img src={bs_img} alt="" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="147.43 100.56 746.22 714.55"
              >
                <path
                  fill="#F2BB2F"
                  opacity="0.8"
                  d="M737.635 255.79C785.984 305.452 841.621 347.741 865.994 412.999C892.522 484.025 905.452 563.453 879.579 635.831C853.191 709.648 797.731 776.831 725.348 806.441C657.545 834.177 586.791 786.552 514.156 785.317C437.496 784.013 358.075 832.744 289.819 799.036C216.883 763.016 165.828 686.354 150.66 605.802C135.85 527.151 174.814 449.887 210.376 377.146C243.423 309.551 287.719 250.468 347.025 204.146C411.571 153.729 482.946 91.1602 562.968 101.74C642.329 112.233 682.111 198.758 737.635 255.79Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
      <svg
        className="about-us-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-251.45 415.15 1780.32 1774.6"
      >
        <path
          fill="#FFF6DD"
          opacity="0.8"
          d="M100.842 761.789C215.412 643.781 310.7 544.202 467.396 484.161C637.942 418.813 871.831 386.348 1053.93 448.146C1239.64 511.171 1413.6 608.822 1497.8 784.069C1576.67 948.229 1430.08 1120.58 1438.44 1296.86C1447.27 1482.91 1579.6 1674.98 1507.4 1841.08C1430.25 2018.57 1249.69 2143.51 1053.93 2181.43C862.786 2218.44 666.616 2124.94 482.107 2039.64C310.649 1960.37 158.367 1853.68 35.1133 1710.4C-99.0307 1554.45 -264.141 1382.1 -250.675 1187.77C-237.321 995.036 -30.7326 897.312 100.842 761.789Z"
        ></path>
      </svg>
    </Layout>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzk=" } }) {
      edges {
        node {
          AboutUsPage {
            mainText
            bottomSection {
              text
              title
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
//highlight-end
