import React, { Component } from "react"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import { BsStarFill } from "react-icons/bs"

export default class Testimonials extends React.Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
  }

  // filters the testimonial and trim it depending on its length
  readmore = index => {
    const listText = document.querySelectorAll(`.slide${index}`)
    const dots = document.querySelectorAll(`span.more${index}`)
    const readmoreBtn = document.querySelectorAll(`p.more${index}`)
    if (
      listText[0].classList.contains("hidden") ||
      listText[1].classList.contains("hidden")
    ) {
      listText[0].classList.remove("hidden")
      listText[1].classList.remove("hidden")
      dots[0].classList.add("hidden")
      dots[1].classList.add("hidden")
      readmoreBtn[0].innerHTML = "read less"
      readmoreBtn[1].innerHTML = "read less"
    } else {
      listText[0].classList.add("hidden")
      listText[1].classList.add("hidden")
      dots[0].classList.remove("hidden")
      dots[1].classList.remove("hidden")
      readmoreBtn[0].innerHTML = "read more"
      readmoreBtn[1].innerHTML = "read more"
    }
  }

  trimContet = (content, index) => {
    if (content.length < 120) return content
    else {
      const firstPart = content.slice(0, 120)
      const secondPart = content.slice(120, content.length)
      let htmlCode = (
        <>
          <p>
            {firstPart}
            <span className={"more" + index}>..</span>
            <span className={"slide" + index + " hidden"}>{secondPart}</span>
          </p>
          <a onClick={() => this.readmore(index)}>
            <p className={"more" + index}>read more</p>
          </a>
        </>
      )
      return htmlCode
    }
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    }
    return (
      <>
        <StaticQuery
          query={graphql`
            query testimonialQuery {
              allWpTestimonial {
                edges {
                  node {
                    testimonial {
                      fieldGroupName
                      clientName
                      content
                      date
                      rating
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <>
              <Slider ref={slider => (this.slider = slider)} {...settings}>
                {data.allWpTestimonial.edges.map((item, index) => {
                  return (
                    <div className="slide" key={index}>
                      <div className="slide__card tes">
                        <h3 className="title">
                          {item.node.testimonial.clientName}
                        </h3>
                        <span className="date">
                          {dateFormatter(item.node.testimonial.date)}
                        </span>
                        <div className="starContainer">
                          {/* creates the stars from acf*/}
                          {[...Array(item.node.testimonial.rating)].map(
                            (e, i) => (
                              <BsStarFill key={i} />
                            )
                          )}
                        </div>

                        {this.trimContet(item.node.testimonial.content, index)}
                      </div>
                    </div>
                  )
                })}
              </Slider>
              <div className="range">
                <input
                  onChange={e => this.slider.slickGoTo(e.target.value)}
                  value={this.state.slideIndex}
                  type="range"
                  min={0}
                  max={data.allWpTestimonial.edges.length}
                />
              </div>
            </>
          )}
        />
      </>
    )
  }
}

const dateFormatter = date => {
  const dateArray = date.split("/")
  const day = dateArray[0]
  const month = dateArray[1] - 1
  const year = dateArray[2]

  const event = new Date(Date.UTC(year, month, day))

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }

  return event.toLocaleDateString("en-US", options).split(",").join(" ")
}
